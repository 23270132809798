import store from '@/store'
/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
import axios from 'axios'
import router, { allRouters } from '@/router'
import _ from 'lodash'
import dayjs from 'dayjs'

/**
 * 如果是北变或者温州北变  需要修改几个字段的展示名称
 * */
export const nameChangeForBB = () => {
  return ["bbelc","bbwz"].includes(sessionStorage.getItem("memberCode"))
}

export function parseTime (time, cFormat) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string')) {
      if ((/^[0-9]+$/.test(time))) {
        // support "1548221490638"
        time = parseInt(time)
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), '/')
      }
    }

    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
    return value.toString().padStart(2, '0')
  })
  return time_str
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime (time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj (url) {
  const search = decodeURIComponent(url.split('?')[1]).replace(/\+/g, ' ')
  if (!search) {
    return {}
  }
  const obj = {}
  const searchArr = search.split('&')
  searchArr.forEach(v => {
    const index = v.indexOf('=')
    if (index !== -1) {
      const name = v.substring(0, index)
      const val = v.substring(index + 1, v.length)
      obj[name] = val
    }
  })
  return obj
}

export const getUrl = (params) => {
  let tempUrl = '?'
  if (params) {
    for (const param in params) {
      if (Object.prototype.hasOwnProperty.call(params, param)) {
        tempUrl = `${tempUrl}${param}=${params[param]}&`
      }
    }
  }
  return tempUrl
}

let seed = 0
const now = Date.now()
export const getUUid = () => {
  return 'mubai_' + now + '_' + (seed++)
}

export const getTreePath = (data, aim, aimNames, names = []) => {
  // 1. 遍历数据，查询到则直接返回
  // 2. 未查询到回调当前函数  参数 children直到查询到符合数据
  let paths = Array.from(names)
  // const result = data.find(item => item[aimNames] === aim)
  // if (result) return [...paths, result[aimNames]]
  for (let i = 0; i < data.length; i++) {
    paths.push(data[i][aimNames])
    if (data[i][aimNames] === aim) {
      break
    }
    const children = data[i].children
    if (children && children.length) {
      paths = getTreePath(children, aim, aimNames, paths)
      if (paths.indexOf(aim) !== -1) break
    }
    paths.pop()
  }
  return paths
}

export const getShowMenu = (data) => {
  if(data[0].name === "Fun" && !data[0].TTT){
    let wepAuth = store.state.user.userInfo.authority
    // 处理单人员多角色 权限合并 ————————Start————————
    {
      wepAuth = wepAuth.split("|||")
      const aSet = new Set()
      wepAuth.forEach(item =>{
        if(item){
          item = JSON.parse(item)
          if(item.checked && item.checked.length > 0){
            item.checked.forEach(p => aSet.add(p))
          }
          if (item.half && item.half.length > 0){
            item.half.forEach(p => aSet.add(p))
          }
        }
      })
      var tempObj = {
        checked: Array.from(aSet),
        half: []
      }
      wepAuth = JSON.stringify(tempObj)
    }
    // 处理单人员多角色 权限合并 ————————End————————
    const authListAll = JSON.parse(wepAuth)
    let authList = [...authListAll.checked, ...authListAll.half]
    authList = authList.filter(item => ['HomeWorkSpace','HomePersonalWorkbench','HomeException','HomeWorkHoursStatistic','OrderHoursStatistic','HomeAbnormalStatistics'].includes(item))
    data[0].children = data[0].children.filter(item => authList.includes(item.name))
    data[0].TTT = true
    return data ? data.filter(item => !item.hidden) : []
  }else {
    return data ? data.filter(item => !item.hidden) : []
  }
}

export const formatColumns = (data, value = 'id', text = 'name') => {
  return data.map(item => ({
    text: item.name,
    value: item.id
  }))
}

export const recoverColumns = (data, value = 'id', text = 'name') => {
  return data.map(item => ({
    id: item.value,
    name: item.text
  }))
}

// 在表头数据中抽离出基础form表单
export const transformColumnsToForm = (columns) => {
  let columnsFormList = []
  columns.forEach(item => {
    if (item.form) {
      const formObj = {
        index: item.form.index || 0,
        label: item.label,
        key: item.form.key || item.prop,
        tagName: item.form.tagName,
        required: 'required' in item.form ? item.form.required : true,
        props: item.form.props || {},
        attrs: item.form.attrs || {},
        colSpan: item.form.colSpan || 12,
        directives:item.directives||[]
      }
      if ('defaultValue' in item.form) {
        formObj.defaultValue = item.form.defaultValue
      }
      if ('rules' in item.form) {
        formObj.rules = item.form.rules
      }
      if ('on' in item.form) {
        formObj.on = item.form.on
      }
      if ('hidden' in item.form) {
        formObj.hidden = item.form.hidden
      }
      // 对于部分标签需要特殊处理  el-select
      if (item.form.tagName === 'el-select') {
        formObj.children = [
          {
            tagName: 'el-option',
            innerValue: item.form.innerValue,
            props: item.form.optionsProps || {
              label: 'name',
              value: 'id'
            },
            options: item.form.options || []
          }
        ]
      }
      if (item.form.tagName === 'el-radio-group') {
        formObj.children = [
          {
            tagName: 'el-radio',
            innerValue: item.form.radioInnerName || 'name',
            options: item.form.options || [],
            props: item.form.optionsProps || {
              label: 'id'
            },
          }
        ]
      }
      if ('slots' in item.form) {
        formObj.children = [
          {
            slots: item.form.slots
          }
        ]
      }
      if ('customSlots' in item.form) {
        formObj.children = [
          {
            customSlots: item.form.customSlots
          }
        ]
      }
      columnsFormList.push(formObj)
    }
  })
  columnsFormList = columnsFormList.sort((a, b) => {
    if (a.index < b.index) return -1
    return 1
  })
  return columnsFormList
}

export const setTreeData = (data, id = -1) => {
  const top = data.filter(item => item.parentId === +id)
  return top.map(item => {
    const children = setTreeData(data, item.id)
    return {
      ...item,
      children: children.length ? children : null
    }
  })
}
//禁用仓库
export const setTreeDataDisabled = (data, id = -1) => {
  const top = data.filter(item => item.parentId === id)
  return top.map(item => {
    const children = setTreeDataDisabled(data, item.id)
    return {
      ...item,
      disabled: item.type === 0,
      children: children.length ? children : null
    }
  })
}
export const getLineIds = (list, value, valueKey = 'id') => {
  const current = list.find(item => item[valueKey] === value);
  return current ? [current[valueKey]].concat(current.parentId !== null ? getLineIds(list, current.parentId, valueKey) : []) : [];
};

// 文件流格式
export function handleDown (filename, url, data) {
  return axios({
    method: 'post',
    url: url, // 请求地址
    data: data,
    responseType: 'blob' // 表明返回服务器返回的数据类型
  }).then((res) => { // 处理返回的文件流
    const content = res.data
    const blob = new Blob([content])
    const fileNames = filename + '.xlsx'
    if ('download' in document.createElement('a')) { // 非IE下载
      const elink = document.createElement('a')
      elink.download = fileNames
      elink.style.display = 'none'
      elink.href = URL.createObjectURL(blob)
      document.body.appendChild(elink)
      elink.click()
      URL.revokeObjectURL(elink.href) // 释放URL 对象
      document.body.removeChild(elink)
    } else { // IE10+下载
      navigator.msSaveBlob(blob, fileNames)
    }
  })
}
export function isInsertNode (value, isLinkRouteName = true) {
  // 留一个后门用来更新角色的权限
  if (router.currentRoute.query && router.currentRoute.query.authSecret === 'df0abebbd41392cae79cbbe944f15b8a2c36af98') {
    return true
  }
  if (value) {
    const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
    // 超级管理员直接放行
    // console.log('userInfo=',userInfo)
    if(userInfo.userName==='admin') return true
    const auths = userInfo.authority.split('|||')
    let authList = []
    auths.forEach(item => {
      const filterChecked = JSON.parse(item).checked
      const filterHalf = JSON.parse(item).half
      authList = [...authList, ...filterChecked, ...filterHalf].map(name=>name.toLocaleLowerCase())
    })
    // 全部转为小写，兼容规范问题
    const routeName = router.currentRoute.name.toLocaleLowerCase()
    value = value.toLocaleLowerCase()
    const newValue = isLinkRouteName ? routeName + value : value
    return authList.includes(newValue)
  }
  return true
}


export function treeToArr (source) {
  let res = []
  source.forEach(el => {
    res.push(el)
    el.children && res.push(...treeToArr(el.children))
  })
  return res
}

export function getFirstRouterName () {
  const name = store.state.user.roles
  let firstName
  for (let i = 0; i < allRouters.length; i++) {
    const item = allRouters[i]
    if (name.indexOf(item.name) !== -1 && (!item.children || !item.children.length || item.path === 'home')) {
      if(item.path !== 'home'){
        firstName = item.name
        break
      }else {
        let wepAuth = store.state.user.userInfo.authority
        // 处理单人员多角色 权限合并 ————————Start————————
        {
          wepAuth = wepAuth.split("|||")
          const aSet = new Set()
          wepAuth.forEach(item =>{
            if(item){
              item = JSON.parse(item)
              if(item.checked && item.checked.length > 0){
                item.checked.forEach(p => aSet.add(p))
              }
              if (item.half && item.half.length > 0){
                item.half.forEach(p => aSet.add(p))
              }
            }
          })
          var tempObj = {
            checked: Array.from(aSet),
            half: []
          }
          wepAuth = JSON.stringify(tempObj)
        }
        // 处理单人员多角色 权限合并 ————————End————————
        const authListAll = JSON.parse(wepAuth)
        let authList = [...authListAll.checked, ...authListAll.half]
        authList = authList.filter(item => ['HomeWorkSpace','HomePersonalWorkbench','HomeException','HomeWorkHoursStatistic','OrderHoursStatistic','HomeAbnormalStatistics'].includes(item))
        let resName = ""
        item.children[0].children.forEach( p => {
          if(resName === "" && authList.includes(p.name)){
            resName = p.name
          }
        })
        if(resName === ""){
          resName = "HomeWorkSpace"
        }
        firstName = resName
        break
      }
    }
  }
  return firstName
}
// 路由层级变成一级
export function generateFlatRoutes (accessRoutes) {
  let flatRoutes = [];
  for (let item of accessRoutes) {
    let childrenFflatRoutes = [];
    if (item.children && item.children.length > 0) {
      childrenFflatRoutes = castToFlatRoute(item.children, [], "");
    }

    // 一级路由是布局路由,需要处理的只是其子路由数据
    flatRoutes.push({
      name: item.name,
      path: item.path,
      component: item.component,
      redirect: item.redirect,
      children: childrenFflatRoutes
    });
  }


  return flatRoutes;
}

/**
 * 将子路由转换为扁平化路由数组（仅一级）
 * @param {待转换的子路由数组} routes
 * @param {父级路由路径} parentPath
 */
function castToFlatRoute (routes, breadcrumb, parentPath, flatRoutes = []) {
  for (let item of routes) {
    if (item.name === 'selfCenter') {
      flatRoutes.push({
        name: item.name,
        path: item.path,
        component: item.component,
        redirect: item.redirect,
        children: item.children,
        meta: item.meta
      })
      // flatRoutes.push({
      //   name: item.name,
      //   path: (parentPath + "/" + item.path),
      //   component: item.component,
      //   meta: item.meta
      // });
    } else {
      if (item.children && item.children.length > 0) {

        let childrenBaseUrl = ''
        if (parentPath == '') {
          childrenBaseUrl = item.path
        } else if (item.path != '') {
          childrenBaseUrl = `${parentPath}/${item.path}`
        }
        let childrenBreadcrumb = _.cloneDeep(breadcrumb)
        if (item.meta.breadcrumb !== false) {
          childrenBreadcrumb.push({
            path: childrenBaseUrl,
            title: item.meta.title
          })
        }
        let tmpRoute = _.cloneDeep(item)
        tmpRoute.path = childrenBaseUrl
        tmpRoute.meta.breadcrumbNeste = childrenBreadcrumb
        delete tmpRoute.children
        flatRoutes.push({
          name: tmpRoute.name,
          path: tmpRoute.path,
          ...tmpRoute
        })
        let childrenRoutes = castToFlatRoute(item.children, childrenBreadcrumb, childrenBaseUrl, flatRoutes)
        childrenRoutes.map(item => {
          // 如果 path 一样则覆盖，因为子路由的 path 可能设置为空，导致和父路由一样，直接注册会提示路由重复
          if (flatRoutes.some(v => v.path == item.path)) {
            flatRoutes.forEach((v, i) => {
              if (v.path == item.path) {
                flatRoutes[i] = item
              }
            })
          } else {
            flatRoutes.push(item)
          }
        })
        // flatRoutes.push({
        //   name: tmpRoute.name,
        //   path: (parentPath + "/" + tmpRoute.path).substring(1),
        //   redirect: tmpRoute.redirect,
        //   meta: {
        //     ...tmpRoute.meta,
        //     breadCrumb:
        //   }
        // });
        // castToFlatRoute(item.children, childrenBreadcrumb, childrenBaseUrl, flatRoutes)


      } else {

        let tmpRoute = _.cloneDeep(item)
        if (parentPath != '' && !isExternalLink(tmpRoute.path)) {
          if (tmpRoute.path != '') {
            tmpRoute.path = `${parentPath}/${tmpRoute.path}`
          } else {
            tmpRoute.path = parentPath
          }
        }
        // 处理面包屑导航
        let tmpBreadcrumb = _.cloneDeep(breadcrumb)
        if (tmpRoute.meta.breadcrumb !== false) {
          tmpBreadcrumb.push({
            path: tmpRoute.path,
            title: tmpRoute.meta.title
          })
        }
        tmpRoute.meta.breadcrumbNeste = tmpBreadcrumb
        flatRoutes.push(
          {
            name: tmpRoute.name,
            path: tmpRoute.path,
            meta: tmpRoute.meta,
            ...tmpRoute
          })

      }
    }
  }

  return flatRoutes;
}
function isExternalLink (path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}
export const getTimeDesc = (start, end) => {
  const format = 'YYYY年MM月DD日 HH:mm'
  if (start && end) {
    const isSameDay = dayjs(start).isSame(end, 'day')
    if (isSameDay) {
      return `${dayjs(start).format(format)} ~ ${dayjs(end).format('HH:mm')}`
    } else {
      return `${dayjs(start).format(format)} ~ ${dayjs(end).format(format)}`
    }
  } else if (start) {
    return `${dayjs(start).format(format)}`
  } else if (end) {
    return `${dayjs(end).format(format)}`
  }
  return '-'
}
export const getTimeDescNo = (start, end) => {
  const format = 'YYYY年MM月DD日'
  if (start && end) {
    const isSameDay = dayjs(start).isSame(end, 'month')
    if (isSameDay) {
      return `${dayjs(start).format(format)} ~ ${dayjs(end).format('DD日')}`
    } else {
      return `${dayjs(start).format(format)} ~ ${dayjs(end).format(format)}`
    }
  } else if (start) {
    return `${dayjs(start).format(format)}`
  } else if (end) {
    return `${dayjs(end).format(format)}`
  }
  return '-'
}

// 保留指定位数小数
export const sliceVal = (valStr, decimal = 3) => {
  valStr+=''
  const decimalLength = valStr.split('.')[1]?.length
  if (decimalLength > decimal) {
    return valStr.slice(0, valStr.length - (decimalLength - decimal)) * 1
  }
  return valStr*1||0
}
